import { inject, Injectable, signal } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import {
  HomepageMetadata,
  PopularGameModel,
  ProviderImageModel,
} from '../models/homepage.model';
import { ClientHttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class HomepageService {
  private url = '/client-app-svc/v1';
  private http = inject(ClientHttpService);
  private getMetadataCache$: Observable<any> | null = null;
  private getProviderImageCache$: Observable<ProviderImageModel[]> | null =
    null;

  metadata = signal<HomepageMetadata>(null as unknown as HomepageMetadata);
  popularGames = signal<PopularGameModel[]>([]);
  providerImages = signal<ProviderImageModel[]>([]);

  getMetadata(): Observable<HomepageMetadata> {
    const data = this.metadata();
    if (data) {
      return of(data);
    }
    if (!this.getMetadataCache$) {
      this.getMetadataCache$ = this.http
        .get<{ data: HomepageMetadata }>(`${this.url}/metadata`, true)
        .pipe(
          map((res) => {
            this.metadata.set(res.data);
            return res.data;
          }),
          shareReplay(1)
        );
    }

    return this.getMetadataCache$;
  }

  getProviderImage(): Observable<ProviderImageModel[]> {
    const url = `${this.url}/provider-imgs`;

    const data = this.providerImages();
    if (data.length) {
      return of(data);
    }

    if (!this.getProviderImageCache$) {
      this.getProviderImageCache$ = this.http
        .get<{ data: ProviderImageModel[] }>(url, true)
        .pipe(
          map((res) => {
            this.providerImages.set(res.data);
            return res.data;
          }),
          shareReplay(1)
        );
    }

    return this.getProviderImageCache$;
  }

  getPopularGames(): Observable<PopularGameModel[]> {
    const url = `${this.url}/popular-games`;
    return this.http
      .get<{ data: PopularGameModel[] }>(url, true)
      .pipe(map((val) => val.data));
  }

  // test lint,   should removed later
}
